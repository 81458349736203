.error_content {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: normal;
  overflow: hidden;
  background: white;
}

.error_title {
  display: block;
  font-size: 50px;
  margin: 0;
  margin-top: 15px;
  padding: 0;
  color: rgb(139, 145, 153) !important;
}

.error_code {
  font-size: 70px;
}

.error_text {
  color: #000000 !important
}
