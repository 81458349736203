:root {
  --color-error: #e7838f;
  --color-success: #75de97;
  --color-transparent: rgba(0, 0, 0, 0);

  --background_color: #282e40;
  --primary_color: #38cfcb;
  --primary_color_dark: #2cb5b1;
  --form_color: #3e4353d9;
  --secondary_color: #a9abb3;
  --secondary_color_dark: #93969e;
  --text_color: #ffffff;
  --menu_background: #ffffffd9;
  --menu_text_color: #282e40;
  --footer_text_color: #ffffff;

  --font: Poppins, sans-serif;
  --font-bold: PoppinsBold, sans-serif;

  --offline_bg_color: #545968;
  --disabled: #ebebeb;
}

@font-face {
    font-family: 'WorkSans';
    src: local('WorkSans'), url("./../fonts/WorkSans-Light.ttf");
}

@font-face {
    font-family: 'Avenir';
    src: local('Avenir'), url("./../fonts/avenir.otf");
}

@font-face {
    font-family: 'AvenirBold';
    src: local('AvenirBold'), url("./../fonts/avenir_bold.otf");
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url("./../fonts/OpenSans-Light.ttf");
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url("./../fonts/Poppins-Light.ttf");
}

@font-face {
    font-family: 'PoppinsBold';
    src: local('PoppinsBold'), url("./../fonts/Poppins-Bold.ttf");
}

body {
    font-family: var(--font);
}

.bolder {
    color: var(--text_color) !important;
    font-family: var(--font-bold);
}
.login-title {
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}
.circular{
    color: var(--primary_color) !important
}
a {
    color: var(--text_color) !important;
    transition: all 0.25s;
}

.flag-icon {
    margin-right: 7px;
}

.dt-login__content-wrapper {
    background-color: transparent;
}

.dt-login__bg-section {
    width: 80%;
    margin-left: 10%;
    text-align: center;
}

.dt-login__bg-section::before {
    background: none;
}

.dt-login__content {
    width: max-content;
    height: max-content;
    border-radius: 10px;
    background-color: var(--form_color) !important;
}

.btn-primary-traces {
    color: var(--text_color) !important;
}

.btn-secondary-traces {
    color: var(--text_color) !important;
}

.btn {
    border-radius: 7px;
}

.btn-primary-traces:hover {
    color: var(--text_color) !important;
}

.btn-secondary-traces:hover {
    color: var(--text_color) !important;
}

.btn-primary-traces.disabled, .btn-primary-traces:disabled {
    color: var(--text_color) !important;
}

.btn-secondary-traces.disabled, .btn-secondary-traces:disabled {
    color: var(--text_color) !important;
}

.btn-primary-traces:not(:disabled):not(.disabled):active, .btn-primary-traces:not(:disabled):not(.disabled).active,
.show > .btn-primary-traces.dropdown-toggle {
    color: var(--text_color) !important;
}

.btn-secondary-traces:not(:disabled):not(.disabled):active, .btn-secondary-traces:not(:disabled):not(.disabled).active,
.show > .btn-secondary-traces.dropdown-toggle {
    color: var(--text_color) !important;
}

.acces-code {
    display: flex;
    width: 100%;
    justify-content: center;
}

.acces-code input {
    width: 48px;
    font-size: 2.2rem;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin: 2px;
    border: 1px solid #55525c;
    background: #21232d;
    font-weight: bold;
    color: var(--text_color);
    outline: none;
    transition: all 0.1s;
}
.acces-code input:focus,.acces-code input:focus-visible, .acces-code input:focus-within   {
    border-color: var(--primary_color_dark);
    box-shadow: 0 0 0 0.2rem var(--primary_color_dark) !important;
}

.dt-login--container {
    padding: 0;
    background: none;
    align-items: flex-start;
}

#logo_static {
    display: none;
}

.dt-login--container {
    transition: all 1s;
}

.custom-checkbox {
    display: inline-table;
    margin-left: 15px;
}

.flag-icon {
    -webkit-filter: grayscale(62.5%);
    filter: grayscale(62.5%);
}

.btn, label, .form-control {
    font-size: 10.3pt;
    font-weight: normal;
}

.form-control {
    background-color: var(--color-transparent) !important;
    border: none;
    border-radius: 0;
    color: white;
    padding-left: 0;
}

.form-control:focus {
    border-bottom-color: var(--text_color) !important;
    color: var(--text_color) !important;
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:hover, 
input.form-control:-webkit-autofill:focus, 
input.form-control:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: var(--text_color) !important;
}

#background {
    position: fixed;

    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    margin: 0px;
    overflow: hidden;

    z-index: 0;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--background-color) !important;
}

.custom-control-label {
    padding-left: 0;
    color: var(--text_color) !important;
}

span.error {
    color: var(--color-error) !important;
}

.text-danger {
    color: var(--color-error) !important;
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.eco {
    position: absolute;
    bottom: 30px;
    color: var(--footer_text_color);
    left: 10px;
    @media screen and (max-width: 767px) {
        bottom: 10px;
        font-size: 17px;
    }
}

#version {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 5px;
    left: 10px;
    bottom: 10px;
    color: var(--footer_text_color);
    font-size: 10px;
}

.dt-login__content-inner {
    padding: 30px;
}

h1 {
    color: var(--text_color) !important;
    margin-bottom: 25px;
}

h3.greetings {
    color: var(--text_color) !important;
    width: 100%;
    text-align: center;
    font-weight: normal;
    margin-bottom: 15px;
}

h3.greetings a {
    font-size: 14px;
    display: block;
    margin-top: 10px;
    font-weight: bold;
}

.input-group {
    background-color: var(--color-transparent) !important;
}

.input-group-text {
    background-color: var(--color-transparent) !important;
    border: none;
    color: var(--text_color) !important;
    padding-left: 5px;
    padding-right: 5px;
}

label {
    color: var(--text_color) !important;
}

p {
    color: var(--text_color) !important;
}

.text-locale-active {
	color: var(--primary_color) !important;
}

.text-locale-inactive {
	color: transparent !important;
}

.text-success {
    color: var(--color-success) !important;
}

.text-info {
    color: var(--text_color) !important;
}

small {
    color: var(--text_color) !important;
}

h3 {
    margin-bottom: 0;
}

div.login-description p.text-title {
    font-size: 65px;
}

div.login-description p.text-description {
    display: none;
    padding-top: 11px;
    font-size: 17px;
}

.palid {
    color: var(--text_color);
}

.tick {
    width: 70px;
    height: 15px;
}

.dt-header {
    background: none;
    box-shadow: none;
    height: 50px;
}

.dt-header__toolbar {
    background: none;
    width: 100%;
    padding: 0;
    padding-right: 110px;
    padding-top: 0;
}

.dt-nav__item {
    font-weight: normal;
}

.dt-nav__link {
    color: var(--menu_text_color) !important;
    height: 20px;
    padding: 11px;
    border-radius: 7px;

    color: black;
    background-color: var(--menu_background);
    border: none;
    font-weight: normal;
}

.dropdown-menu {
    background-color: var(--menu_background);
}

.dropdown-item {
    color: var(--menu_text_color) !important;
}

.dropdown-item:hover {
    background: none;
}

.dropdown-toggle::after {
    font-size: 11px;
    margin-left: 5px;
}

.dt-nav__item {
    align-items: center;
}

.first-row {
    width: 100%;
    padding-top: 35px;
}

body {
    overflow: auto;
}

.btn {
    padding: 1.25rem 1.7rem;
    border-radius: 7px !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
}

.notification-message .title {
    color: var(--text_color) !important;
}

.notification-success {
    background-color: #07bc0c !important;
}

.notification-warning {
    background-color: #e74c3c !important;
}

.text-warning {
    color: #f37d00 !important;
}

footer {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center;
    color: var(--footer_text_color);
}

#mobile-keyboard-footer {
    display: none;
    color: var(--footer_text_color);
    font-size: 1.4rem;
}

body {  
    background-color: var(--background_color) !important; 
}

#background {
    background-color: var(--background_color) !important;
}

#loader {
    background-color: var(--background_color) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--background_color) !important;
    background-color: var(--background_color) !important;
}         

a:hover {
    color: var(--primary_color) !important;
}

.btn-primary-traces {
    background-color: var(--primary_color) !important;
    border: var(--primary_color) !important;
}     

.btn-primary-traces:hover {
    background-color: var(--primary_color_dark) !important;
    border-color: var(--primary_color_dark) !important;
}

.btn-primary-traces:focus, .btn-primary-traces.focus {
    box-shadow: 0 0 0 0.2rem var(--primary_color_dark) !important;
}

.btn-primary-traces.disabled, .btn-primary-traces:disabled {
    background-color: var(--primary_color_dark) !important;
    border-color: var(--primary_color_dark) !important;
}

.btn-primary-traces:not(:disabled):not(.disabled):active, 
.btn-primary-traces:not(:disabled):not(.disabled).active,
.show > .btn-primary-traces.dropdown-toggle {
    background-color: var(--primary_color_dark) !important;
    border-color: var(--primary_color_dark) !important;
}

.tick {
    background-color: var(--primary_color) !important
}

.dt-nav__link:hover {
    color: var(--primary_color) !important
}

.dt-nav__link:focus {
    color: var(--primary_color) !important
}

.dt-nav__link:active {
    color: var(--primary_color) !important
}

.dropdown-item:hover {
    color: var(--primary_color) !important
}

.input-group {
    border-bottom: 1px solid var(--primary_color_dark) !important;
}

.btn-secondary-traces {
    background-color: var(--secondary_color) !important;
    border: var(--secondary_color) !important;  
}

.btn-secondary-traces:hover {
    background-color: var(--secondary_color_dark) !important;
    border-color: var(--secondary_color_dark) !important;
}

.btn-secondary-traces:focus, .btn-secondary-traces.focus {
    box-shadow: 0 0 0 0.2rem var(--secondary_color_dark) !important;
}

.btn-secondary-traces.disabled, .btn-secondary-traces:disabled {
    background-color: var(--secondary_color_dark) !important;
    border-color: var(--secondary_color_dark) !important;
}

.btn-secondary-traces:not(:disabled):not(.disabled):active, 
.btn-secondary-traces:not(:disabled):not(.disabled).active,
.show > .btn-secondary-traces.dropdown-toggle {
    background-color: var(--secondary_color_dark) !important;
    border-color: var(--secondary_color_dark) !important;
}

#loader-logo {
    text-align: center;
    color: var(--text_color);
}

.dt-loader-container img {
    height: auto;
    width: 200px;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    margin: auto;
}

#login-container {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#login-container-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.login-description {
    margin-right: 25px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo_anim {
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: auto;
    display: flex;
    margin-bottom: 25px;
}

#logo_anim_secondary {
    display: none;
}

#offline {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 10px;
  background-color: var(--offline_bg_color);
  color: white;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 0;
  padding: 1px;
  transition: height 0.5s ease-in-out; 
}

#offline.offline {
    height: 20px;
  }
  
  #offline.online {
    height: 0;
    padding: 0;
  }

.dt-header--fixed .dt-header.offline {
    padding-top: 40px !important;
}

.password-toggle {
    cursor: pointer;
    opacity: 0.85;
}

.fa-eye, .fa-eye-slash {
    font-size: 16px !important;
}

.dropdown-locale::after {
	content: '' !important;
}

.dropdown-locale span {
	margin-right: 7px;
}
