@media (max-width: 1366px) {
    div.login-description {
        padding-left: 0;
    }

    div.login-description p.text-title {
        font-size: 50px;
    }

    .tick {
        width: 50px;
    }  

    .btn {
        padding: .8rem 1.7rem;
    }

    .input-group > .form-control::placeholder {
        font-size: 11px !important;
    }
}

@media (max-width: 1100px) {
    .dt-login__bg-section {
        width: 85%;
    }    

    div.login-description p.text-title {
        font-size: 40px;
    } 
    
    div.login-description p.text-description {
        font-size: 14px;
    }
}

@media (max-width: 991.98px) {
    #login-container {
        width: 85% !important;
    }

    .dt-login__bg-section {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 925px) { 
    div.login-description p.text-title {
        font-size: 30px;
    } 
    
    div.login-description p.text-description {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .dt-login__bg-section {
        width: 100%;
    }    

    .col-md-6 {
        padding-right: 0;
        width: 100%;
    }

    .dt-login__content {
        width: 92% !important;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 50px;
    }

    .dt-brand__logo-img {
        height: 100px !important;
        width: auto !important;
        margin-top: 50px;
        margin-bottom: 35px;
    }    

    .dt-login__bg-section {
        padding-right: 0;
    }

    .dt-header__toolbar {
        padding-right: 25px;
    }   

    .first-row {
        padding-top: 7px;
    }

    .tick {
        display: none;
    }  

    div.login-description {
        width: 85%;
        margin-right: 0;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;
    } 

    div.login-description p.text-title {
        font-size: 30px;
    }

    .dt-login__bg-section {
        padding-bottom: 0;
    }

    .dt-login__content {
        margin-top: 5px !important;
    }

    .row {
        margin-right: 0;
    }

    .dt-login__content {
        margin-bottom: 0%;
    }

    .dt-login--container {
        align-items: center;
    }

    .dt-login__bg-section {
        padding-top: 0;
    }

    .dt-login__content {
        background: none !important;
    }

    .form-control {
        background-color: #626874 !important;
        border: none;
        border-radius: 10px;
        color: white;
        padding: 25px;
    }

    .input-group > .form-control:not(:first-child) {
        border-radius: 10px;
    }   

    .input-group {
        border-bottom: none;
    }

    .input-group-prepend {
        position: absolute;
        right: 0;
        top: 9px;
        z-index: 1000;
    }

    .input-group-prepend.password {
        font-size: 20px;
    }

    .input-group-prepend.password {
        display: none;
    }

    .btn {
        padding: 17px 1.7rem;
    }

    .custom-checkbox {
        padding-left: 0;
        margin-left: 25px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: var(--color-primary-light) !important;
        background-color: var(--color-primary-light) !important;
    }

    .custom-control-label::before {
        width: 17px;
        height: 17px;
    }

    .custom-control-label::after {
        top: 3px !important;
        left: -22px !important;
        width: 1.25rem;
        height: 1.25rem;
        background-size: contain;
    }

    .btn-submit-login {
        width: 100% !important;
    }

    .btn-secondary-login {
        width: 100% !important;
		margin-left: 0 !important;
		margin-top: 15px;
    }

    .input-group > .form-control::placeholder {
        font-size: 13.5px !important;
    }

    .input-group {
        border-bottom: none !important;
    }

    #login-container-inner {
        display: block;
    }

    #logo_anim_main {
        display: none !important;
    }

    #logo_anim_secondary {
        display: flex !important;
    }

    #version {
        width: 100%;
        text-align: center;
        left: 0;
    }

    footer {
        bottom: 25px;
    }
}

@media (max-width: 660px) {
    div.login-description {
        width: 90%;
        font-size: 
    } 

    div.login-description p.text-title {
        font-size: 23px;
    }
}

@media (max-width: 575px) {
    .custom-control-label::after {
        top: 4px !important;
        left: -15px !important;
        width: 1.4rem;
        height: 1.25rem;
        background-size: contain;
    }

    .custom-control-label {
        padding-left: 5px;
    }

    .custom-checkbox {
        margin-left: 21px;
    }

    .dt-brand__logo-img {
        height: 85px !important;
        margin-bottom: 25px;
    }

    #login-container {
        width: 100% !important;
    }
}

@media (max-width: 500px) { 
    div.login-description p.text-title {
        font-size: 23px;
    }

    footer {
        font-size: 11px;
    }

    #mobile-keyboard-footer {
        font-size: 11px;
    }

    .form-control {
        border-radius: 7px;
        padding: 20px;
    }

/*    .input-group > .form-control:not(:first-child) {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }*/   

    .input-group {
        border-bottom: none;
    }

    .input-group-prepend {
        top: 7px;
    }

    .input-group-prepend .icon {
        font-size: 18px;
    }

    .btn {
        padding: 10px 1.7rem;
    }

    .custom-control-label::after {
        top: 4px !important;
        left: -15px !important;
        background-size: contain;
    }

    .password-toggle {
        top: 5px !important;
    }
}

@media (max-width: 385px) {
    div.login-description-parent {
        width: 75% !important;
    } 

    div.login-description p.text-title {
        font-size: 20px;
    }

    footer {
        font-size: 10px;
        padding-left: 7px;
        padding-right: 7px;
    }  

    #mobile-keyboard-footer {
        font-size: 10px;
        padding-left: 7px;
        padding-right: 7px;
    }

    .form-control {
        border-radius: 5px;
        padding: 16px;
    }

/*    .input-group > .form-control:not(:first-child) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }  */ 

    .input-group {
        border-bottom: none;
    }

    .input-group-prepend {
        top: 4px;
    }

    .input-group-prepend .icon {
        font-size: 15px;
    }

    .btn {
        padding: 7px 1.7rem;
    }

    .btn, label, .form-control {
        font-size: 11px;
    }

    .custom-control-label::after {
        top: 4px !important;
        left: -14px !important;
        width: 1.1rem;
        height: 1.1rem;
    }

    .input-group > .form-control::placeholder {
        font-size: 10px !important;
    }

    .dt-brand__logo-img {
        height: 70px !important;
        margin-bottom: 10px;
    } 

    .password-toggle {
        top: 1px !important;
    }
}

@media (max-width: 300px) {
    .btn {
        width: 100% !important;
        display: block;
        margin: 0 !important;
        margin-bottom: 9px !important;
        font-size: 11px;
    }

    div.login-description-parent {
        width: 80% !important;
    } 

    div.login-description p.text-title {
        font-size: 17px;
    }

    .input-group > .form-control::placeholder {
        font-size: 8px !important;
    }
}

@media (max-height: 500px) {
    footer {
        display: none;
    }

    #mobile-keyboard-footer {
        display: block;
    }
}
